<template>
  <div>
    <v-card v-if="fuelCells.length">
      <v-card-title>
        <span>Fuel Cells</span>
        <div class="flex-grow-1"></div>
        <v-text-field xs12 :color="siteColor" v-model="search" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table
        v-if="fuelCells.length"
        :headers="headers"
        :items="fuelCells"
        :items-per-page="50"
        item-key="id"
        class="elevation-1"
        :search="search"
        sort-by="name"
        :mobile-breakpoint="0"
      >
        <template v-slot:body="{items}">
          <tbody>
            <template v-for="item in items">
              <tr @click="routeTo(item.name)" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ formatSensors(item.sensors.items) }}</td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      search: "",
      currentItem: "",
      checkedSensors: false,
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Sensors",
          value: "",
        },
      ],
    };
  },
  async created() {
    await this.$store.dispatch("fuelcell/allFuelCells");
  },
  computed: {
    ...mapState("fuelcell", ["fuelCells"]),
  },
  methods: {
    routeTo(route) {
      this.$router.push(`/fuelcell/${route}`);
    },
    formatSensors(items) {
      return items
        .map((item) => item.name)
        .sort()
        .join(", ");
    },
  },
};
</script>

<style lang="scss" scoped>
tr {
  cursor: pointer;
}
table {
  .v-text-field {
    border-color: #ffffff1f;
    padding-top: 0px;
    margin-top: 0px;
  }
}
</style>